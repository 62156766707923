.checkbox-grouped-options {
  .flex {
    gap: 8px;
    align-items: center;
    font-size: 14px;
  }
  .grid {
    display: flex !important;
    flex-direction: column;
    gap: 16px;
  }
  
  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div.flex.items-baseline > div.typography_body {
      font-weight: 500;
    }
  }
}