.custom-segment {
  background-color: var(--constant-gray-200) !important;
  border-radius: 6px;

  .ce-ant-segmented-item-label {
    min-width: 100px !important;
    line-height: unset;
    min-height: unset;
    margin: 8px 12px;
  }

  .ce-ant-segmented-item {
    font-size: 14px;
    color: var(--constant-primary-navy);
  }

  .ce-ant-segmented-item-selected {
    border-radius: 4px;
    color: var(--constant-primary-blue) !important;
  }
}